var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-9 col-lg-9 col-md-9 col-12 h-100"},[_c('appProgressSteps',{attrs:{"propsData":_vm.stepId,"shId":_vm.shId}})],1),_vm._m(0)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9 col-md-9 col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(1),_c('div',{staticClass:"row justify-content-center selectableCard"},[_c('div',{staticClass:"col-lg-4 col-12"},[_vm._m(2),(!_vm.getPoliceCheckSingle.payment_ref)?_c('div',{staticClass:"sits-border-info p-3"},[_c('dl',{staticClass:"row"},[(
                      _vm.getPoliceCheckSingle &&
                      _vm.getPoliceCheckSingle.product &&
                      _vm.getPoliceCheckSingle.product.name
                    )?_c('dd',{staticClass:"col-sm-8"},[_vm._v(" Police check - "+_vm._s(_vm.getPoliceCheckSingle.product.name)+" ")]):_vm._e(),(
                      _vm.getPoliceCheckSingle &&
                      _vm.getPoliceCheckSingle.unit_price
                    )?_c('dd',{staticClass:"col-sm-4 text-right"},[_vm._v(" "+_vm._s(_vm.getPoliceCheckSingle.product.unit_price)+" ")]):_vm._e(),_c('dt',{staticClass:"col-sm-8 fw-700"},[_vm._v("Total")]),(
                      _vm.getPoliceCheckSingle &&
                      _vm.getPoliceCheckSingle.product &&
                      _vm.getPoliceCheckSingle.product.unit_price
                    )?_c('dd',{staticClass:"col-sm-4 fw-700 text-right"},[_vm._v(" "+_vm._s(_vm.getPoliceCheckSingle.product.unit_price)+" ")]):_vm._e()])]):_vm._e(),(_vm.getPoliceCheckSingle.payment_ref)?_c('div',{staticClass:"sits-border-info p-3 text-center"},[_vm._m(3)]):_vm._e(),(!_vm.getPoliceCheckSingle.payment_ref)?_c('div',{ref:"card"}):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right pt2000"},[_c('button',{staticClass:"btn-secondary btn-sm mr-2 btn-w-100",on:{"click":function($event){return _vm.fnPreviousStep()}}},[_vm._v(" Previous ")]),(!_vm.getPoliceCheckSingle.payment_ref)?_c('button',{staticClass:"btn-info btn-sm btn-w-100",on:{"click":function($event){return _vm.processPayment()}}},[(_vm.loading)?_c('b-spinner',{staticClass:"mr-2",attrs:{"small":""}}):_vm._e(),(_vm.loading)?_c('span',[_vm._v("Loading...")]):_c('span',[_vm._v("Pay now")])],1):_vm._e(),(_vm.getPoliceCheckSingle.payment_ref)?_c('button',{staticClass:"btn-info btn-sm btn-w-100",on:{"click":function($event){return _vm.fnNextStep()}}},[_vm._v(" Next ")]):_vm._e()])])])])]),_c('div',{staticClass:"col-lg-3 col-md-3 col-12"},[_c('div',{staticClass:"card h-overflow"},[_c('div',{staticClass:"card-body"},[_c('appStep3Ins')],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-3 col-lg-3 col-md-3 col-12 h-100"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[_c('img',{staticStyle:{"height":"77px"},attrs:{"src":require("../../assets/img/cv-screen-marketing-1.jpeg")}})])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_c('h5',[_vm._v("Payment")]),_c('div',{staticClass:"underline"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticClass:"pb-3",attrs:{"src":require("../../assets/img/credit_card_logos_x_4.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-0"},[_vm._v(" Payment completed "),_c('i',{staticClass:"far fa-check-circle text-success"})])}]

export { render, staticRenderFns }